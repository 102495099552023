import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
// service pages
import Home from './pages/home/home'
import General from './pages/services/general/general'
import QRCode from './pages/services/qrcode/qrcode'
import Tables from './pages/services/tables/tables'
import Screen from './pages/services/screen/screen'
import IDCard from './pages/services/id_card/id_card'
import DriverLicence from './pages/services/driverLicence/driverLicence'
import CarCertificate from './pages/services/carCertificate/carCertificate'
import CarInvoice from './pages/services/carInvoice/carInvoice'
import VatInvoice from './pages/services/vatInvoice/vatInvoice'
import TrainInvoice from './pages/services/trainInvoice/trainInvoice'
import TaxiInvoice from './pages/services/taxiInvoice/taxiInvoice'
import QuotaInvoice from './pages/services/quotaInvoice/quotaInvoice'
import DigitalNumber from './pages/services/digitalNumber/digitalNumber'
// template pages
import KV from './pages/templates/kv/kv'
import TablesConfig from './pages/templates/tables/tables'
// 404
import NotFound from './pages/notfound/notfound'

class Routes extends Component {
  render() {
    return (
      <Switch>
        {/* services */}
        <Route exact path="/" component={Home} />
        <Route path="/general" component={General} />
        <Route path="/qrcode" component={QRCode} />
        <Route path="/tables" component={Tables} />
        <Route path="/screen" component={Screen} />
        <Route path="/id_card" component={IDCard} />
        <Route path="/driverLicence" component={DriverLicence} />
        <Route path="/carCertificate" component={CarCertificate} />
        <Route path="/carInvoice" component={CarInvoice} />
        <Route path="/vatInvoice" component={VatInvoice} />
        <Route path="/trainInvoice" component={TrainInvoice} />
        <Route path="/taxiInvoice" component={TaxiInvoice} />
        <Route path="/quotaInvoice" component={QuotaInvoice} />
        <Route path="/digitalNumber" component={DigitalNumber} />
        {/* templates */}
        <Route path="/templates/kv" component={KV} />
        <Route path="/templates/tables" component={TablesConfig} />
        {/* 404 */}
        <Route path="*" exact component={NotFound} />
      </Switch>
    )
  }
}
export default Routes
