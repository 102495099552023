import React, { Component, Suspense } from "react";

const NavbarPage = React.lazy(() =>
  import("../../components/Navbar/Navbar_Page")
);
// const Section = React.lazy(() => import("./section"));
const Service = React.lazy(() => import("../../components/Service/service"));
const Templates = React.lazy(() =>
  import("../../components/Templates/templates")
);
// const Feature = React.lazy(() => import("../../components/Features/feature"));
// const Clients = React.lazy(() => import('../../components/Clients/Clients'));
const Footer = React.lazy(() => import("../../components/Footer/footer"));

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        // 备用sections
        // { id: 1, idnm: "home", navheading: "首页" },
        { id: 2, idnm: "services", navheading: "服务" },
        { id: 3, idnm: "templates", navheading: "模板" },
        // { id: 0 , idnm : "features", navheading: "领域" },
        // { id: 0 , idnm : "clients", navheading: "合作伙伴" },
        // { id: 0 , idnm : "about", navheading: "关于我们" },
      ],
      pos: document.documentElement.scrollTop,
      imglight: false,
      navClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "", imglight: false });
    }
  };

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          {/* Importing Navbar */}
          <NavbarPage
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />

          {/* Importing section */}
          {/* <Section /> */}

          {/* Importing service */}
          <Service />
          <Templates />

          {/* Importing feature */}
          {/* <Feature /> */}

          {/* Importing clients */}
          {/* <Clients /> */}

          {/* Importing footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default Home;
