import React, { Component, Suspense } from 'react'
import data from '../../../ApiIp'
const NavbarPage = React.lazy(() =>
  import('../../../components/Navbar/Navbar_Page')
)
const Section = React.lazy(() => import('./section'))
const Feature = React.lazy(() =>
  import('../../../components/Service/detector-subpages')
)
const Footer = React.lazy(() => import('../../../components/Footer/footer'))

class VatInvoice extends Component {
  constructor(props) {
    super(props)
    this.state = {
      navItems: [{ id: 1, idnm: 'home', navheading: '' }],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: 'navbar-light',
      url_post: data.vatInvoice.url,
    }
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          {/* Importing Navbar */}
          <NavbarPage
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
            isLight={true}
          />

          {/* Importing section */}
          <Section />

          {/* Importing feature */}
          {/* {console.log(111, url)} */}
          <Feature url_post={this.state.url_post} />

          {/* Importing footer */}
          <Footer />
        </Suspense>
      </React.Fragment>
    )
  }
}

export default VatInvoice
