import React from 'react'
// import routes from "./routes";
import { BrowserRouter } from 'react-router-dom'
import Routes from './routes'

// Import Scss
import './theme.scss'

//Import Icon Css
import './assets/css/materialdesignicons.min.css'
import './assets/css/pe-icon-7.css'

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter forceRefresh={true}>
        <Routes />
      </BrowserRouter>
    </React.Fragment>
  )
}
